import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import AppNavigation from '@/components/AppNavigation';
import Sidebar from '@/components/Sidebar/Sidebar';
import { UI_MEDIAQUERIES } from '@/ui-mediaqueries';
const { isOpen, ...props } = Sidebar.props || {};

const desktopMediaQuery = window.matchMedia(
  UI_MEDIAQUERIES.SIDEBAR_NOT_COLLAPSED
);

const printMediaQuery = window.matchMedia(UI_MEDIAQUERIES.PRINT);

export default Vue.component('SidebarWrapper', {
  components: {
    AppNavigation,
    Sidebar,
  },
  props,
  computed: {
    ...mapGetters(['sidebarIsOpen']),
    ...mapGetters('authentication', ['hasIncompleteDomains']),
    ...mapGetters('quota', ['storageFull', 'storageAlmostFull']),
    ...mapState({
      darkModeState: (state) => state.settings.darkMode,
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.closeMobileSidebar();
      },
    },
  },
  created() {
    if (desktopMediaQuery.addEventListener) {
      desktopMediaQuery.addEventListener('change', this.handleResize);
    } else {
      desktopMediaQuery.addListener(this.handleResize);
    }
    this.setMobileMenu(!desktopMediaQuery.matches);
    this.getQuotaInformation();
  },
  beforeDestroy() {
    if (desktopMediaQuery.removeEventListener) {
      desktopMediaQuery.removeEventListener('change', this.handleResize);
    } else {
      desktopMediaQuery.removeListener(this.handleResize);
    }
  },
  methods: {
    ...mapActions(['closeMobileSidebar', 'setMobileMenu', 'toggleSidebar']),
    ...mapActions('authentication', ['logout']),
    ...mapActions('quota', ['getQuotaInformation']),
    handleResize(ev) {
      //apparently the browser modifies the width of the document during print causing the matchMedia to trigger a change event
      //we should ignore it to prevent the sidebar from disappearing.
      if (printMediaQuery.matches) {
        return;
      }

      if (ev.matches) {
        this.setMobileMenu(false);
      } else {
        this.setMobileMenu(true);
      }
    },
    async onLogout() {
      await this.logout();
    },
  },
  render(createElement) {
    return createElement(Sidebar, {
      on: {
        toggleSidebar: this.toggleSidebar,
        logout: this.onLogout,
      },
      props: {
        ...this.$props,
        isOpen: this.sidebarIsOpen,
        showStorageBar: this.storageAlmostFull || this.storageFull,
        mailApp: true,
        showFeedbackButton: !this.hasIncompleteDomains,
        darkMode: this.darkModeState,
      },
      scopedSlots: {
        ...this.$scopedSlots,
        navigation: () =>
          createElement(AppNavigation, {
            class: 'sidebar__app-navigation',
            props: {
              showLabels: true,
            },
          }),
      },
    });
  },
});
