<template>
  <div>
    <slot name="storage-full-warning" v-if="storageFull" />
    <slot name="storage-almostFull-warning" v-else-if="storageAlmostFull" />
    <div class="storage-bar">
      <p
        v-if="loadingStorageInfo"
        :key="`loading_${loadingStorageInfo}`"
        v-translate
      >
        Loading current storage information…
      </p>
      <p v-else-if="loadingStorageInfoError" v-translate>
        Could not show storage information. Please reload the page.
      </p>
      <p v-else class="storage-bar__label">
        <strong
          >{{ storageSpaceUsed | formatDataSize }}
          <translate>out of</translate>
          {{ totalStorageSpace | formatDataSize }}
          <translate>used</translate></strong
        >
      </p>
      <div class="storage-bar__bar">
        <div
          v-if="this.storageSpaceUsed"
          class="storage-bar__coloured"
          :style="{ width: percentageWidth + '%' }"
          :class="generateColourClass"
        />
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import formatDataSize from '@/lib/formatDataSize';

  export default {
    name: 'StorageBar',
    filters: {
      formatDataSize,
    },
    data() {
      return {
        loadingStorageInfo: false,
        loadingStorageInfoError: false,
      };
    },
    computed: {
      ...mapGetters('quota', [
        'storageFull',
        'storageAlmostFull',
        'storagePercentageUsed',
      ]),
      ...mapState({
        storageSpaceUsed: (state) => state.entities.quota.storage_used,
        totalStorageSpace: (state) => state.entities.quota.storage_total,
      }),
      generateColourClass() {
        if (this.storageFull) {
          return 'storage-bar__coloured__error';
        }
        if (this.storageAlmostFull) {
          return 'storage-bar__coloured__warning';
        }
        return 'storage-bar__coloured__success';
      },
      percentageWidth() {
        return Math.round(this.storagePercentageUsed);
      },
    },
    created() {
      if (!this.storageSpaceUsed || !this.totalStorageSpace) {
        this.loadingStorageInfo = true;
        this.getQuotaInformation()
          .catch((error) => {
            this.loadingStorageInfoError = true;
            throw error;
          })
          .finally(() => {
            this.loadingStorageInfo = false;
          });
      }
    },
    methods: {
      ...mapActions('quota', ['getQuotaInformation']),
    },
  };
</script>

<style src="./StorageBar.scss" lang="scss" />
