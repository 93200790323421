






























































  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import Icon from '@/components/Icon/Icon.vue';
  import flattenFileTree from '@/lib/flattenFileTree';

  export const statusEnum = {
    FAILED: 'FAILED',
    INVALID: 'INVALID',
  };

  export default Vue.extend({
    name: 'FileUpload',
    components: {
      Icon,
    },
    model: {
      prop: 'files',
      event: 'change',
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      files: {
        type: Array,
        default: () => [],
      },
      limit: {
        type: Number,
        default: 0,
      },
      required: {
        type: Boolean,
        default: false,
      },
      requiredErrorMessage: {
        type: String,
        default: '',
      },
      validate: {
        type: Boolean,
        default: false,
      },
      accept: {
        type: Array,
        default: () => [],
      },
      unacceptedFileErrorMessage: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isDraggingFiles: false,
        unacceptedFilesAdded: false,
      };
    },
    computed: {
      removeFileText(): string {
        return this.$gettext('Remove file');
      },
      validationErrorMessage(): string {
        if (this.valid) return '';
        if (this.unacceptedFilesAdded) return this.unacceptedFileErrorMessage;
        if (this.required && this.files.length < 1)
          return this.requiredErrorMessage;
        return '';
      },
      valid(): boolean {
        return (
          this.required && this.files.length > 0 && !this.unacceptedFilesAdded
        );
      },
    },
    watch: {
      valid() {
        (this.$refs.file as HTMLInputElement).setCustomValidity(
          this.validationErrorMessage
        );
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      browse() {
        (this.$refs.file as HTMLInputElement).click();
      },
      addFiles(files: FileList | File[]) {
        const acceptedFiles = Array.from(files).filter(
          (file) =>
            !this.accept.length ||
            this.accept.includes(file.type) ||
            this.accept.includes(file.name.substr(file.name.lastIndexOf('.')))
        );
        const allFiles = [...this.files, ...acceptedFiles];

        this.unacceptedFilesAdded = acceptedFiles.length < files.length;

        // reset the value of the file input field after each change to allow selecting the same file again
        (this.$refs.file as HTMLInputElement).value = '';

        if (this.limit > 0 && allFiles.length > this.limit) {
          const message = this.$gettextInterpolate(
            this.$gettext('Cannot add more than %{ limit } attachments'),
            {
              limit: this.limit,
            }
          );
          this.setToastMessage({ message });

          return;
        }

        this.$emit('change', allFiles);
      },
      removeFile(fileIndex: number) {
        this.$emit(
          'change',
          this.files.filter((file, index) => index !== fileIndex)
        );
      },
      onDragOver(ev: DragEvent) {
        if (
          ev.dataTransfer &&
          Array.from(ev.dataTransfer.types).includes('Files')
        ) {
          ev.preventDefault();
          ev.dataTransfer.dropEffect = 'copy';
          this.isDraggingFiles = true;
        }
      },
      onDragLeave() {
        this.isDraggingFiles = false;
      },
      async onDrop(ev: DragEvent) {
        if (this.isDraggingFiles) {
          ev.preventDefault();
          this.isDraggingFiles = false;

          try {
            const files = ev.dataTransfer
              ? await flattenFileTree(ev.dataTransfer)
              : [];
            this.addFiles(files);
            this.$emit('drop', files);
          } catch (err) {
            this.setToastMessage({
              message: this.$gettext(
                'Something went wrong while adding the files, please try again.'
              ),
            });
          }
        }
      },
      failedOrInvalid(status: string) {
        return status === statusEnum.FAILED || status === statusEnum.INVALID;
      },
      fileStatusText(status: string) {
        switch (status) {
          case statusEnum.FAILED:
            return this.$gettext('failed');
          case statusEnum.INVALID:
            return this.$gettext('invalid');
        }
      },
    },
  });
