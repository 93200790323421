













































































  import api from '@/api';
  import Checkbox from '@/components/form/Checkbox.vue';
  import FileUpload from '@/components/FileUpload/FileUpload.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import SelectInput from '@/components/form/SelectInput.vue';
  import TextAreaField from '@/components/form/TextAreaField.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import { detect } from 'detect-browser';
  import { mapActions } from 'vuex';
  import Vue from 'vue';

  const MAXIMUM_ATTACHMENTS = 5;

  interface label {
    [key: string]: string;
  }
  interface modal {
    isOpen: boolean;
    setBeforeCloseCallback: Function;
    setLoading: Function;
    toggleModal: Function;
  }

  export default Vue.extend({
    name: 'FeedbackModal',
    components: {
      Checkbox,
      FileUpload,
      Icon,
      Modal,
      SelectInput,
      TextAreaField,
      ModalConfirmActions,
    },
    data() {
      return {
        formSubmitting: false,
        allowReceiveEmail: false,
        MAXIMUM_ATTACHMENTS,
        feedbackSubject: '',
        feedback: '',
        attachments: [],
        browser: {
          name: '',
          version: '',
          os: '',
        } as BrowserInformation,
      };
    },
    computed: {
      hasSelectedSubject(): boolean {
        return this.feedbackSubject !== '';
      },
      textAreaLabel(): string {
        return this.labels[this.feedbackSubject];
      },
      allowSubmit(): boolean {
        return (
          this.hasSelectedSubject &&
          !this.formSubmitting &&
          this.feedback?.length > 0
        );
      },
      receiveUpdatesText(): string {
        return this.$gettext(
          'I want to receive updates via mail about my feedback'
        );
      },
      selectSubjectText(): string {
        return this.$gettext('Please select a subject');
      },
      submitText(): string {
        return this.$gettext('Submit');
      },
      labels(): label {
        return {
          bug: this.$gettext('Bug description'),
          compliment: this.$gettext('I really like…'),
          suggestion: this.$gettext('It would be awesome if…'),
          question: this.$gettext('Ask us anything'),
        };
      },
      subjectOptions(): HTMLSelectOption[] {
        return [
          {
            name: this.$gettext('Things I would change in StartMail'),
            value: 'suggestion',
          },
          {
            name: this.$gettext('I found a bug'),
            value: 'bug',
          },
          {
            name: this.$gettext('Things I like about StartMail'),
            value: 'compliment',
          },
          {
            name: this.$gettext('I have a question'),
            value: 'question',
          },
        ];
      },
      chooseSubjectText(): string {
        return this.$gettext('Choose a subject');
      },
      modal(): modal {
        return this.$refs.modal as unknown as modal;
      },
    },
    created() {
      this.browser = detect() as BrowserInformation;
    },
    methods: {
      ...mapActions(['setToastMessage']),
      toggleModal() {
        if (!this.modal.isOpen) {
          this.modal.setBeforeCloseCallback(this.reset);
        }
        this.modal.toggleModal();
      },
      setSubmitting(isSubmitting: boolean) {
        this.formSubmitting = isSubmitting;
        this.modal.setLoading(isSubmitting);
      },
      async postFeedback(feedbackPayload: FeedbackSubmitPayload) {
        this.setSubmitting(true);
        try {
          await api.feedback.submit(feedbackPayload);
        } catch (err) {
          this.setToastMessage({
            message: this.$gettext('An error occurred when sending feedback.'),
          });
          throw err;
        } finally {
          this.setSubmitting(false);
        }
        this.setToastMessage({
          message: this.$gettext('Thanks for your feedback!'),
        });
        this.reset();
        this.modal.toggleModal();
      },
      submitForm() {
        let feedbackPayload: FeedbackSubmitPayload = {
          type: this.feedbackSubject,
          message: this.feedback,
          receive_email: this.allowReceiveEmail,
          attachments: this.attachments,
        };

        if (this.browser) {
          const { name, version, os } = this.browser;
          const browser = { name, version, os };
          feedbackPayload = {
            ...feedbackPayload,
            browser,
          };
        }
        this.postFeedback(feedbackPayload);
      },
      reset() {
        this.formSubmitting = false;
        this.allowReceiveEmail = false;
        this.feedbackSubject = '';
        this.feedback = '';
        this.attachments = [];
      },
    },
  });
